<template>
    <section id="web-synthesizer" class="trunk">
        <iframeCompo coid="WebSynth" :enable_email="false">
            <template #foreword>
                <h2>Website Synthesizer</h2>
                <p>
                    Please paste the company website information into the input box below. This function synthesizes the website and 
                    creates a comprehensive overview for copy-n-paste.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import iframeCompo from "../components/iframeCompo.vue";

export default {
    name: 'WebsynthView',
    components: {
        iframeCompo,
    },
}
</script>

<style scoped>
</style>