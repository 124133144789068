<template>
    <section id="immigrun" class="trunk">
        <div id="op-wrap">
            <router-link to="/" custom v-slot="{ navigate }">
                <button @click="navigate">
                    <span v-if="!cnDisp">Home</span>
                    <span v-else class="lang-cn">首页</span>
                </button>
            </router-link>
            <select @change="(event) => $router.push(event.target.value)" style="width: 110px;">
                <option value="/immigrun">ImmigRun</option>
                <option value="/cvbuild">CVbuilder</option>
            </select>
            <button @click="toggleForm">
                <span v-if="!postDisp && !cnDisp">ServPost</span>
                <span v-if="!postDisp && cnDisp" class="lang-cn">服务发布</span>
                <span v-if="postDisp"><i class="fas fa-arrow-left"></i> Back</span>
            </button>
            <select v-model="curLang" @change="cnDisp = (curLang === 'cn' || curLang === 'tcn')">
                <option value="en">English</option>
                <option value="fr">Français</option>
                <option value="cn" class="lang-cn">简体中文</option>
                <option value="tcn" class="lang-cn">繁體</option>
                <option value="kor">한국어</option>
                <option value="es">Español</option>
                <option value="pt">Português</option>
                <option value="far" class="lang-far">فارسی</option>
            </select>
        </div>

        <iframeCompo coid="ImmigRun" :enable_email="false" :visible_frame="!postDisp" :lang="curLang">
            <template #foreword>
                <div v-if="curLang === 'en'">
                    <h2>AI Immigration Consultant</h2>
                    <p>
                        Our GPT-powered immigration consultant possesses a thorough and comprehensive understanding of laws, regulations, and policies governing <strong>immigration</strong>, <strong>refugee</strong> status, <strong>study abroad</strong>, and foreign visiting in Canada, Australia, New Zealand, and the United States. <!--He also has extensive resources for all kinds of services.-->
                    </p>
                    <div class="gpt-greet">
                        Hello! How can I assist you today?
                        <p>
                            You can share your situation and ask any questions. I will help you get started on your right foot and make well-informed decisions for your success.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'fr'">
                    <h2>Consultant en Immigration IA</h2>
                    <p>
                        Notre consultant en immigration alimenté par GPT possède une compréhension approfondie et complète des lois, réglementations et politiques régissant <strong>l'immigration</strong>, le statut de <strong>réfugié</strong>, les <strong>études à l'étranger</strong>, et les voyages internationaux au Canada, en Australie, en Nouvelle-Zélande et aux États-Unis.
                    </p>
                    <div class="gpt-greet">
                        Bonjour ! Comment puis-je vous aider aujourd'hui ?
                        <p>
                            Vous pouvez partager votre situation et poser toutes vos questions. Je vous aiderai à bien démarrer et à prendre des décisions éclairées pour votre réussite.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'cn'" class="lang-cn">
                    <h2>“润博士” AI 移民留学顾问</h2>
                    <p>
                        我们的人工智能移民顾问对<u>加拿大</u>、<u>澳大利亚</u>、<u>新西兰</u>和<u>美国</u>的移民和难民法、公民法、以及相关规定和政策有着深入且全面的掌握。<!--他也了解各种相关的服务资源。-->在此, 他将尽心解答您有关移民、留学、难民身份、以及跨国旅行的任何问题。此外，他还熟知中国的《民法典》和《公司法》等法律法规，可以帮您对一些具体情况，比如涉及国籍等的各种问题，做法律层面的细致解读。
                    </p>
                    <div class="gpt-greet">
                        您好！欢迎咨询任何移民和留学问题！请详细描述您的情况, 我会帮您深入分析并优选规划，助您顺利实现梦想。
                    </div>
                </div>
                <div v-if="curLang === 'tcn'" class="lang-cn">
                    <h2>AI 移民留學顧問</h2>
                    <p>
                        我們的人工智慧移民顧問對<u>加拿大</u>、<u>澳洲</u>、<u>紐西蘭</u>和<u>美國</u>的移民和難民法、公民法，以及相關規定和政策有著深入且全面的掌握。<!--他也了解各種相關的服務資源。-->在此，他將盡心解答您有關移民、留學、難民身份，以及跨國旅行的任何問題。
                    </p>
                    <div class="gpt-greet">
                        您好！歡迎諮詢任何移民和留學問題！請詳細描述您的情況，我會幫您深入分析並優選規劃，助您順利實現夢想。
                    </div>
                </div>
                <div v-if="curLang === 'kor'">
                    <h2>AI 이민 유학 상담사</h2>
                    <p>
                        저희 인공지능 이민 상담사는 캐나다, 호주, 뉴질랜드, 그리고 미국의 이민 및 난민법, 시민법, 관련 규정 및 정책에 대해 깊고 포괄적인 지식을 가지고 있습니다. 여기서, 이민, 유학, 난민 신분, 그리고 국제 여행에 관한 모든 질문에 성심껏 답변해 드리겠습니다.
                    </p>
                    <div class="gpt-greet">
                        안녕하세요! 이민 및 유학에 관한 모든 질문을 환영합니다! 상황을 자세히 설명해 주시면, 저는 심층 분석과 최적의 계획을 도와드리겠습니다. 꿈을 이루는 데에 성공적으로 나아가실 수 있도록 돕겠습니다.
                    </div>
                </div>
                <div v-if="curLang === 'es'">
                    <h2>Consultor de Inmigración IA</h2>
                    <p>
                        Nuestro consultor de inmigración impulsado por GPT posee un conocimiento profundo y completo de las leyes, regulaciones y políticas que rigen la <strong>inmigración</strong>, el estatus de <strong>refugiado</strong>, los <strong>estudios en el extranjero</strong> y los viajes internacionales en Canadá, Australia, Nueva Zelanda y los Estados Unidos.
                    </p>
                    <div class="gpt-greet">
                        ¡Hola! ¿En qué puedo ayudarte hoy?
                        <p>
                            Puedes compartir tu situación y hacer cualquier pregunta. Te ayudaré a comenzar con el pie derecho y a tomar decisiones informadas para tu éxito.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'pt'">
                    <h2>Consultor de Imigração IA</h2>
                    <p>
                        Nosso consultor de imigração baseado em GPT possui um conhecimento profundo e abrangente das leis, regulamentos e políticas que regem a <strong>imigração</strong>, o status de <strong>refugiado</strong>, os <strong>estudos no exterior</strong> e as viagens internacionais no Canadá, Austrália, Nova Zelândia e Estados Unidos.
                    </p>
                    <div class="gpt-greet">
                        Olá! Como posso te ajudar hoje?
                        <p>
                            Você pode compartilhar sua situação e fazer qualquer pergunta. Vou te ajudar a começar bem e tomar decisões bem-informadas para o seu sucesso.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'far'" class="lang-far">
                    <h2>مشاور هوش مصنوعی برای مهاجرت و تحصیل در خارج</h2>
                    <p>
                        مشاور مهاجرت ما که بر اساس GPT عمل می‌کند، دارای درک کامل و جامع از قوانین، مقررات و سیاست‌های مربوط به <strong>مهاجرت</strong>، وضعیت <strong>پناهندگی</strong>، <strong>تحصیل در خارج</strong> و سفرهای بین‌المللی به کانادا، استرالیا، نیوزیلند و ایالات متحده است.
                    </p>
                    <div class="gpt-greet">
                        سلام! چطور می‌توانم امروز به شما کمک کنم؟
                        <p>
                            می‌توانید وضعیت خود را به اشتراک بگذارید و هر سؤالی که دارید بپرسید. من به شما کمک می‌کنم که کار خود را با موفقیت شروع کنید و تصمیمات آگاهانه‌ای برای موفقیت خود بگیرید.
                        </p>
                    </div>
                </div>
            </template>

            <template #afterword>
                <div id="immig-form" v-show="postDisp">
                    <div v-if="curLang === 'en'">
                        <h3>Service Profile Submission Form</h3>
                        <p>
                            For businesses serving new immigrants and international students, you are invited to post your services using the form below and subscribe to the promotion service delivered by this AI Consultant.
                        </p>
                        <p>
                            Once you submit the form, our AI will extract information from your company website and create a company profile, making it inclusive for future retrieval as part of AI-driven promotions.
                        </p>
                    </div>
                    <div v-if="curLang === 'fr'">
                        <h3>Formulaire de Soumission de Profil de Service</h3>
                        <p>
                            Pour les entreprises qui desservent les nouveaux immigrants et les étudiants internationaux, vous êtes invités à lister vos services en utilisant le formulaire ci-dessous et à souscrire au service de promotion fourni par cet AI Consultant.
                        </p>
                        <p>
                            Une fois que vous aurez soumis le formulaire, notre intelligence artificielle extraira des informations de votre site Web et créera un profil d'entreprise, l'incluant pour une utilisation future dans le cadre de promotions pilotées par l'IA.
                        </p>
                    </div>
                    <div v-if="curLang === 'cn'" class="lang-cn">
                        <h3>服务商信息发布申请表</h3>
                        <p>
                            服务于移民留学人群的上下游公司请用下表申请发布服务内容并订购AI推介。
                        </p>
                        <p>
                            在您提交申请后, 我们的人工智能将从您公司网站提取信息, 存档后作为人工智能将来的检索来源和推介参考。
                        </p>
                    </div>
                    <div v-if="curLang === 'tcn'" class="lang-cn">
                        <h3>服務商信息發布申請表</h3>
                        <p>
                            為新移民與留學生提供服務的上下游公司，請使用下列表格申請發布服務內容，並訂購AI推介。
                        </p>
                        <p>
                            提交後，我們的人工智慧將會從您公司網站提取資訊，並建立公司檔案，作為未來人工智慧推廣和檢索的依據。
                        </p>
                    </div>
                    <div v-if="curLang === 'kor'">
                        <h3>서비스 제공자 정보 게시 신청서</h3>
                        <p>
                            이민자 및 유학생을 대상으로 서비스를 제공하는 관련 기업은 아래 양식을 사용하여 서비스 내용을 신청하고, AI 추천을 구독해 주세요.
                        </p>
                        <p>
                            제출 후, 저희 AI가 귀사의 웹사이트에서 정보를 추출하고 이를 바탕으로 향후 AI 기반 검색과 추천에 참고할 수 있는 회사 프로필을 생성할 것입니다.
                        </p>
                    </div>
                    <div v-if="curLang === 'es'">
                        <h3>Formulario de Envío de Perfil de Servicio</h3>
                        <p>
                            Para las empresas que atienden a nuevos inmigrantes y estudiantes internacionales, están invitadas a listar sus servicios utilizando el formulario a continuación y a suscribirse al servicio de promoción ofrecido por este Consultor de IA.
                        </p>
                        <p>
                            Una vez que envíen el formulario, nuestra IA extraerá información de su sitio web y creará un perfil de empresa, haciéndolo accesible para futuras consultas como parte de las promociones impulsadas por IA.
                        </p>
                    </div>
                    <div v-if="curLang === 'pt'">
                        <h3>Formulário de Submissão de Perfil de Serviço</h3>
                        <p>
                            Para as empresas que atendem novos imigrantes e estudantes internacionais, você está convidado a listar seus serviços usando o formulário abaixo e a assinar o serviço de promoção oferecido por este Consultor de IA.
                        </p>
                        <p>
                            Após o envio do formulário, nossa IA irá extrair informações do site da sua empresa e criar um perfil corporativo, tornando-o disponível para futuras consultas como parte das promoções baseadas em IA.
                        </p>
                    </div>
                    <div v-if="curLang === 'far'" class="lang-far">
                        <h3>فرم درخواست انتشار اطلاعات ارائه دهنده خدمات</h3>
                        <p>
                            برای کسب‌وکارهایی که به مهاجران جدید و دانشجویان بین‌المللی خدمات ارائه می‌دهند، از شما دعوت می‌شود که با استفاده از فرم زیر خدمات خود را فهرست کنید و از خدمات تبلیغاتی ارائه شده توسط این مشاور هوش مصنوعی استفاده کنید.
                        </p>
                        <p>
                            پس از ارسال فرم، هوش مصنوعی ما اطلاعات را از وب‌سایت شرکت شما استخراج کرده و یک پروفایل شرکت ایجاد می‌کند که برای بازیابی‌های آینده به عنوان بخشی از تبلیغات مبتنی بر هوش مصنوعی قابل استفاده خواهد بود.
                        </p>
                    </div>
                    <form @submit.prevent="submitForm">
                        <p>
                            <label>
                                <span v-if="!cnDisp">*Contact:</span>
                                <span v-else class="lang-cn">*联系人:</span>
                            </label>
                            <input class="input-full" type="text" v-model="agent.name" placeholder="Full name like Mr. John Doe" required />
                        </p>
                        <p>
                            <label>
                                <span v-if="!cnDisp">*Phone:</span>
                                <span v-else class="lang-cn">* 手机:</span>
                            </label>
                            <input class="input-full" type="tel" v-model="agent.phone" pattern="\+[0-9]{1,3}[-\s]?[(]?[0-9]{1,5}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}" placeholder="Cell # like +1 (416) 123-4567" required />
                        </p>
                        <p>
                            <label>
                                <span v-if="!cnDisp">*Email:</span>
                                <span v-else class="lang-cn">* 电邮:</span>
                            </label>
                            <input id="email-input" class="input-full" type="email" v-model="agent.email" placeholder="Business email" required />
                        </p>
                        <p>
                            <label>
                                <span v-if="!cnDisp">*Passcode:</span>
                                <span v-else class="lang-cn">*验证码:</span>
                            </label>
                            <span class="input-full">
                                <input type="number" v-model="agent.ecode" placeholder="received from email" required />
                                <button type="button" v-if="!cnDisp" @click="requestPasscode">Request Passcode</button>
                                <button type="button" v-else @click="requestPasscode">获取验证码</button>
                            </span>
                        </p>
                        <p>
                            <label>
                                <span v-if="!cnDisp">*Website:</span>
                                <span v-else class="lang-cn">* 网址:</span>
                            </label>
                            <input class="input-full" type="text" v-model="agent.website" placeholder="Company url, like abc.com/about" required />
                        </p>
                        <p>
                            <label>
                                <span v-if="!cnDisp">Caption:</span>
                                <span v-else class="lang-cn">简注:</span>
                            </label>
                            <input class="input-full" type="text" v-model="agent.caption" placeholder="Keyword-based narration (Max 100 bytes)" maxlength="100" />
                        </p>
                        <p v-if="submissionBusy" style="justify-content: center;">
                            <img src="@/assets/spinnerBlue.gif" alt="Wait..." style="width: 80px" />
                        </p>
                        <p v-if="submissionRtnMsg" style="margin-top: 32px;">
                            &emsp; <i class="fa fa-check" style="color: green; font-size: xx-large;"></i> 
                            &emsp; {{ timestamp }}
                            &emsp; <span style="text-decoration: underline;">{{ submissionRtnMsg }}</span>
                        </p>
                        <div class="btn-container">
                            <button type="submit">
                                <span v-if="!cnDisp">Submit</span>
                                <span v-else>提 交</span>
                            </button>
                        </div>
                    </form>
                </div>

                <div v-if="curLang === 'en' && !postDisp">
                    <!--p>
                        This AI immigration consultant has a <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad" target="_blank" rel="noopener noreferrer" @click.stop="">clone</a> accessible through OpenAI ChatGPT.
                    </p-->
                    <p>
                        For your privacy and data security, please refrain from sharing any personal identification information.
                    </p>
                    <h3>Resume Builder</h3>
                    <p>
                        This <router-link to="/cvbuild">AI Resume Builder</router-link> helps convert your CV from other languages into a professional American-style English format. Even better, it tailors your resume to match respective job descriptions for a perfect fit.
                    </p>
                    <h3>Business Partnership</h3>
                    <p>
                        For businesses serving new immigrants and international students, please click <a href="#" @click.prevent="toggleForm">HERE</a> to list your services.
                    </p>
                    <h3>Disclaimer</h3>
                    <p>
                        This digital immigration consultant is powered by AI, specifically the GPT model, and has been trained on relevant legal texts, government program materials, and service market data. It provides immigration advice and solutions. However, as an AI but a human being, it is not eligible to obtain the licenses and certifications required for lawyers, registered consultants, or licensed advisors in the immigration field. Please exercise caution when considering the advice and solutions offered. AI Directly, the creator of this AI immigration consultant, assumes no responsibility for outcomes resulting from your reliance on its information output.
                    </p>
                </div>
                <div v-if="curLang === 'fr' && !postDisp">
                    <p>
                        Pour votre confidentialité et la sécurité de vos données, veuillez ne pas partager d'informations d'identification personnelle.
                    </p>
                    <h3>Partenariat Commercial</h3>
                    <p>
                        Pour les entreprises desservant les nouveaux immigrants et les étudiants internationaux, veuillez cliquer <a href="#" @click.prevent="toggleForm">ICI</a> pour lister vos services.
                    </p>
                    <h3>Avertissement</h3>
                    <p>
                        Ce consultant en immigration numérique est alimenté par l'IA, spécifiquement le modèle GPT, et a été formé sur des textes juridiques pertinents, des matériaux de programmes gouvernementaux et des données de marché des services. Il fournit des conseils et des solutions en matière d'immigration. Cependant, étant une IA et non un être humain, il n'est pas éligible pour obtenir les licences et certifications requises pour les avocats, les consultants enregistrés ou les conseillers agréés dans le domaine de l'immigration. Veuillez faire preuve de prudence lors de la prise en compte des conseils et des solutions proposés. AI Directly, le créateur de ce consultant en immigration IA, n'assume aucune responsabilité pour les résultats découlant de votre dépendance à ses informations.
                    </p>
                </div>
                <div v-if="curLang === 'cn' && !postDisp" class="lang-cn">
                    <!--p>
                        这个人工智能移民顾问还可以通过 OpenAI ChatGPT 上的 <a href="https://chatgpt.com/g/g-5jA5DOoao-yi-min-liu-xue-nan-min-lu-xing-qian-zheng-zhong-guo-min-fa-dian-he-gong-si-fa-deng" target="_blank" rel="noopener noreferrer" @click.stop="">副本</a> 进行访问。
                    </p-->
                    <p>
                        为了保护您的隐私和数据安全，请避免提供任何个人真实身份信息。比如可以用“张三”替代真实姓名。
                    </p>
                    <h3>求职简历 AI 写手</h3>
                    <p>
                        这个 <router-link to="/cvbuild">求职简历人工智能</router-link> 帮你把中文简历转换成北美风格的英文简历。针对不同的企业和工作，它甚至会裁量每一份简历以契合工作要求描述。
                    </p>
                    <h3>业务伙伴</h3>
                    <p>
                        服务于移民留学人群的上下游公司请按 <a href="#" @click.prevent="toggleForm"><u>这里</u></a> 发布服务内容。
                    </p>
                    <h3>免责声明</h3>
                    <p>
                        这位数字移民顾问由人工智能 GPT 模型驱动, 精通诸多相关法律和政府移民计划，掌握相关服务市场方面的大量信息。它给您提供移民和留学方面的建议和方案。然而, 作为一个人工智能而非人类, 它无缘获得律师资格或注册移民顾问资格。所以请谨慎参考它提供的建议和方案。AI Directly, 这位人工智能移民顾问的制造者，不承担因您依赖其信息输出而产生的任何后果。
                    </p>
                </div>
                <div v-if="curLang === 'tcn' && !postDisp" class="lang-cn">
                    <!--p>
                        這個人工智慧移民顧問還可以透過 OpenAI ChatGPT 上的 <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad" target="_blank" rel="noopener noreferrer" @click.stop="">副本</a> 進行訪問。
                    </p-->
                    <p>
                        為了保護您的隱私和資料安全，請避免提供任何個人的真實身份資訊。您可以使用“張三”來代替真實姓名。
                    </p>
                    <h3>業務夥伴</h3>
                    <p>
                        服務新移民與留學生的上下游企業請按 <a href="#" @click.prevent="toggleForm"><u>這裡</u></a> 列出您的服務內容。
                    </p>
                    <h3>免責聲明</h3>
                    <p>
                        這位數位移民顧問由人工智慧GPT模型驅動，精通諸多相關法律及政府移民計劃，掌握相關服務市場方面的大量資訊。它為您提供移民和留學方面的建議和方案。然而，作為一個人工智慧而非人類，它無法獲得律師資格或註冊移民顧問資格。因此請謹慎參考它提供的建議和方案。AI Directly，這位人工智慧移民顧問的製造者，不承擔因您依賴其資訊輸出而產生的任何後果。
                    </p>
                </div>
                <div  v-if="curLang === 'kor' && !postDisp">
                    <p>
                        고객님의 개인정보와 데이터 보안을 위해, 개인 신원 정보를 제공하지 않도록 주의해 주세요.
                    </p>
                    <h3>비즈니스 파트너</h3>
                    <p>
                        이민자 및 유학생을 위한 서비스를 제공하는 기업은 <a href="#" @click.prevent="toggleForm">여기</a>를 클릭하여 서비스를 등록해 주세요.
                    </p>
                    <h3>면책 조항</h3>
                    <p>
                        이 디지털 이민 컨설턴트는 인공지능 GPT 모델로 구동되며, 관련 법률과 정부의 이민 프로그램을 숙지하고 관련 서비스 시장에 대한 방대한 정보를 가지고 있습니다. 이를 통해 이민 및 유학에 관한 조언과 솔루션을 제공합니다. 그러나 인공지능으로서 변호사 자격이나 등록된 이민 컨설턴트 자격을 얻을 수는 없습니다. 따라서 제공되는 조언과 솔루션을 신중히 고려하시기 바랍니다. AI Directly, 이 AI 이민 컨설턴트의 제조사는 정보 의존 결과에 대해 어떠한 책임도 지지 않습니다.
                    </p>
                </div>
                <div  v-if="curLang === 'es' && !postDisp">
                    <p>
                        Para proteger su privacidad y seguridad de datos, por favor no comparta ninguna información de identificación personal.
                    </p>
                    <h3>Asociación Comercial</h3>
                    <p>
                        Para las empresas que atienden a nuevos inmigrantes y estudiantes internacionales, haga clic <a href="#" @click.prevent="toggleForm">AQUÍ</a> para listar sus servicios.
                    </p>
                    <h3>Descargo de responsabilidad</h3>
                    <p>
                        Este consultor de inmigración digital está impulsado por IA, específicamente por el modelo GPT, y ha sido entrenado en textos legales relevantes, materiales de programas gubernamentales y datos del mercado de servicios. Proporciona asesoramiento y soluciones sobre inmigración. Sin embargo, como una IA y no un ser humano, no es elegible para obtener las licencias y certificaciones requeridas para abogados, consultores registrados o asesores licenciados en el campo de la inmigración. Por favor, ejerza precaución al considerar los consejos y soluciones ofrecidos. AI Directly, el creador de este consultor de inmigración IA, no asume ninguna responsabilidad por los resultados derivados de su confianza en su información.
                    </p>
                </div>
                <div  v-if="curLang === 'pt' && !postDisp">
                    <p>
                        Para garantir sua privacidade e a segurança de seus dados, por favor, não compartilhe nenhuma informação de identificação pessoal.
                    </p>
                    <h3>Parceria Comercial</h3>
                    <p>
                        Para empresas que atendem novos imigrantes e estudantes internacionais, clique <a href="#" @click.prevent="toggleForm">AQUI</a> para listar seus serviços.
                    </p>
                    <h3>Aviso Legal</h3>
                    <p>
                        Este consultor de imigração digital é alimentado por IA, especificamente pelo modelo GPT, e foi treinado em textos legais relevantes, materiais de programas governamentais e dados do mercado de serviços. Ele oferece conselhos e soluções de imigração. No entanto, como uma IA e não um ser humano, ele não é elegível para obter as licenças e certificações necessárias para advogados, consultores registrados ou conselheiros licenciados na área de imigração. Por favor, tenha cautela ao considerar os conselhos e soluções oferecidos. AI Directly, a criadora deste consultor de imigração IA, não assume nenhuma responsabilidade pelos resultados decorrentes da sua dependência das informações fornecidas.
                    </p>
                </div>
                <div v-if="curLang === 'far' && !postDisp" class="lang-far">
                    <p>
                        برای حفظ حریم خصوصی و امنیت اطلاعات شما، لطفاً از به اشتراک گذاشتن هرگونه اطلاعات شخصی خودداری کنید.
                    </p>
                    <h3>همکاری تجاری</h3>
                    <p>
                        برای کسب‌وکارهایی که به مهاجران جدید و دانشجویان بین‌المللی خدمات ارائه می‌دهند، لطفاً برای فهرست کردن خدمات خود <a href="#" @click.prevent="toggleForm">اینجا</a> کلیک کنید.
                    </p>
                    <h3>سلب مسئولیت</h3>
                    <p>
                        این مشاور مهاجرت دیجیتال توسط مدل هوش مصنوعی GPT به کار گرفته شده است، در قوانین مربوطه و برنامه‌های دولتی مهاجرت تبحر دارد و اطلاعات گسترده‌ای در مورد بازار خدمات مرتبط دارد. این سیستم پیشنهادات و راه‌حل‌هایی در زمینه مهاجرت و تحصیل در خارج ارائه می‌دهد. با این حال، به عنوان یک هوش مصنوعی و نه انسان، واجد شرایط دریافت مجوزهای لازم برای وکلا یا مشاوران مهاجرت ثبت‌شده نیست. بنابراین لطفاً در نظر گرفتن پیشنهادات و راه‌حل‌های ارائه شده احتیاط کنید. AI Directly، سازنده این مشاور مهاجرت AI، هیچ مسئولیتی در قبال عواقب ناشی از تکیه بر اطلاعات آن ندارد.
                    </p>
                </div>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { DateTime } from 'luxon';
import { ref, onMounted, nextTick } from 'vue';
import iframeCompo from "../components/iframeCompo.vue";

export default {
    name: 'ImmigView',
    components: {
        iframeCompo,
    },
    data() {
        return {
            agent: {
                name: '',
                phone: '',
                email: '',
                ecode: '',      // email passcode, potentially there could be a SMS passcode called tcode
                website: '',
                caption: ''
            },
            timestamp: '',
            submissionRtnMsg: '',
            submissionBusy: false
        }
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const langParam = queryParams.get('lang');
        const curLang = ref(langParam || 'en');
        const cnDisp = ref(['cn', 'tcn'].includes(curLang.value));
        const postDisp = ref(queryParams.get('page') === 'post');     // 'postDisp' controls whether to show business listing page and its value is toggled by @click of <button>

        onMounted(() => {
            // Add a stamp of 'FREE' on the <iframe>
            const frameHolder = document.getElementById("frame-holder");
            if (frameHolder) {
                const stamp = document.createElement("img");
                stamp.src = "/img/FREE.png";
                stamp.id = 'stamp';
                frameHolder.appendChild(stamp);
            }
        });

        return { curLang, cnDisp, postDisp };
    },
    
    methods: {
        // note: when postDisp is true, the <iframe> is not visible, so event.date.frame_height won't update along content change such while the language changes
        // therefore postHeight() needs to be triggered in the <iframe> content when toggleForm() back to <iframe>
        toggleForm() {
            this.postDisp = !this.postDisp;
            this.submissionRtnMsg = '';
            //this.submissionBusy = false;

            nextTick(() => {
                if (this.postDisp) {
                    document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' })
                } else {
                    // The content in <iframe> may have updated for many times and <iframe> height haven't caught up
                    // So, we have to trigger postHeight() in aidi-func.js in <iframe> after the browser renders the content html.
                    // The triggering moment is set by this nextTick()
                    document.getElementById("page-frame").contentWindow.postMessage({}, '*');
                }
            });
        },

        requestPasscode() {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.agent.email || !pattern.test(this.agent.email)) {
                alert(`IV01: Invalid email address.`);
                return;
            }
                
            // disable the Email input first
            document.getElementById('email-input').disabled = true;

            //
            fetch(`${process.env.VUE_APP_API_URL}/mfacode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', tag: this.agent.email })
            })
            .then(response => {
                if (response.ok) {
                    alert(`A passcode has been sent to your email box.\n\nIt expires in ${process.env.VUE_APP_PASSCODE_LIFECYCLE} minutes.`);
                } else {  
                    alert(`IV02: ${response.status} - ${response.statusText}\n\nDetails: ${JSON.stringify(response.json())}`);
                }
            })
            .catch(error => {
                alert(`IV03: Request failed due to networking issues. Please try again later.\n\n${error.message}`);
            });
        },

        submitForm() {
            if (this.submissionBusy) {    // because the server response takes time, multi submissions during this period shalll be prevented
                return;
            } else {
                this.submissionBusy = true;
            }

            // validate the length of agent.caption, which could be in a language different from English
            const byteBuf = new TextEncoder().encode(this.agent.caption);
            if (byteBuf.length > process.env.IMMIG_FORM_CAPTION_LEN) {
                alert("IV11: The Caption input is too long.");
                return;
            }

            // validate the value of agent.website
            try {
                let { website } = this.agent;
                if (!website.startsWith('https://') && !website.startsWith('http://')) {
                    website = 'https://' + website; 
                }
                new URL(website);     // validate the url input for the Website, otherwise an error may occur

            } catch (_) {       // catches the error on 'new URL()'
                alert("IV12: Invalid URL for the Website input.");
                return;
            }

            // proceed to the server
            fetch(`${process.env.VUE_APP_API_URL}/chat/immiform`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', ...this.agent })
            })
            .then(response => {
                if (response.ok) {
                    this.timestamp = `${DateTime.now().setZone("America/Toronto").toFormat('yyyy-MM-dd, HH:mm:ss')} EDT`;
                    this.submissionRtnMsg = `Your submission is successfully accepted and will be processed shortly.`;
                    this.agent = { name: '', phone: '', email: '', ecode: '', website: '', caption: '' };     // reset the <form>

                    setTimeout(() => {
                        document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' })
                    }, 50);
                } else {              // This tends to handle HTTP status codes that are in 400 and 500 families, but may also include that in the uncommon 100 and 300 families
                    const msg = (response.status < 500) 
                        ? `Failed to submit. Please correct your data or maybe shorten it and try again.` 
                        : `Submission failed due to a server error. Please inform info@ai-directly.com or try it later.`;

                    alert(`IV13: ${response.status} - ${response.statusText}\n\n${msg}\n\n${ JSON.stringify(response.json()) }`);
                }
            })
            .catch(error => {       // catches the error from 'fetch'
                alert(`IV14: Submission failed due to network errors. Please try again later.\n\n${error.message}`);
            })
            .finally(() => {
                this.submissionBusy = false;
            });
        }
    }
}
</script>

<style scoped>
h2 {
    margin-top: 16px;
}

/****************** */
/* Language control */
/****************** */
.lang-cn,
.lang-cn p,
.lang-cn h2,
.lang-cn h3 {
    letter-spacing: 0.06em;
}

.lang-cn u {
    text-decoration: none;
    box-shadow: 0 1px 0 0 gray;
}

.lang-far p,
.lang-far h2,
.lang-far h3 {
    text-align: right;
}

.lang-far h3,
.lang-far p {
    font-size: larger;
}


/************* */
/* Control bar */
/************* */
#op-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 12px 6% 0 0;
}

#op-wrap select,
#op-wrap button {
    width: 90px;
    height: 32px;
    margin: auto 8px;
    padding: 4px;
    background-color: #fbf0e6;
    border: 1px dashed lightgray;
    border-radius: 6px;
    box-shadow: none;
    color: #004b7a;
    font-family: Verdana, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

#op-wrap select:focus,
#op-wrap button:focus {
    outline: none;
    border-color: #004b7ae0;
}

@media screen and (max-width: 1023px) {
    #op-wrap {
        margin-right: 2%;
    }

    #op-wrap select,
    #op-wrap button {
        width: 84px;
        height: 30px;
        margin-right: 2px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: normal;
    }
}


/********* */
/* Form UI */
/********* */
#immig-form {
    margin-top: 32px;
    padding-bottom: 32px;
}

form {
    max-width: 1024px;
    padding: 16px 0;
}

form p {
    display: flex;    /* Enables flexbox */
    align-items: center;    /* Centers items vertically */
    width: 100%;
}

form label {
    flex: 20%;    /* Does not grow, does not shrink, takes up 20% of the space */
    padding-right: 16px;
    text-align: right;
}

form input {
    padding: 15px 12px 13px 12px;
    border: 1px solid lightgray;
    border-radius: 3px;
    font-size: inherit;
}

form input:focus {
    outline: none;
    border-color: #004b7ae0;
}

.input-full {
    flex: 80%;    /* Takes the remaining space from <label> */
}

span.input-full {
    flex: 83%;
    display: inline-flex;
}

.input-full input {
    flex: 60%;    /* Takes the partial space within .input-full */
    margin: 0 12px 0 0;
}

.input-full button {
    flex: 40%;
    background-color: #fbf0e6;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.2s ease;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.input-full button:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}


.input-full button:active {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
    background-color: #f0e0d0; /* Slightly darker shade when pressed */
}

.btn-container {
    display: flex;
    justify-content: center;
}

.btn-container button {
    width: 38%;
    margin-top: 40px;
    padding: 13px 10px 11px 10px;
    border-color: gray;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.3s ease;
    background: #004b7ae0;
    color: white;
    cursor: pointer;
    font-size: inherit;
    place-items: center;
}

.btn-container button:hover {
    border-color: lightgray;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #004b7ad0, #004b7af0);
}

.btn-container button:disabled {
    cursor: not-allowed;     /* Cursor style to indicate it's not clickable */
}

@media screen and (max-width: 1023px) {
    form label {
        flex: 24%;
        padding-right: 6px;
    }

    form input {
        padding: 10px;
    }

    .input-full {
        flex: 76%;
    }

    span.input-full {
        flex: 82%;
    }

    .input-full input {
        flex: 42%;
        width: 40px;       /* to counter offset VUE3 default */
        min-width: 40px;    /* to counter offset VUE3 default */
        margin-right: 8px;
    }

    .input-full button {
        flex: 58%;
        font-family: Verdana, sans-serif;
        font-size: smaller;
    }

    .btn-container button {
        width: 68%;
        margin-top: 26px;
        padding: 10px;
    }
}


/* ************************************************************************************ */
/* below is the setting to take off the incremental/incremental spin from Number Input
/* For WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>

<style>

/**************** */
/* iframe control */
/**************** */
#stamp {
    position: absolute; 
    top: -18px; 
    right: 48px; 
    z-index: 10; 
    width: 60px; 
    height: 56px;
}

@media screen and (max-width: 1023px) {
    #stamp {
        top: -14px;
        width: 52px; 
        height: 48px;
    }
}
</style>