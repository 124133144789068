<template>
    <section id="career" class="trunk">
        <h2>Job Opennings</h2>
        <h3>Software Sales Representative - Remote</h3>
        <p>
            We are looking for sales representatives who have burning desires to work in the artificial intelligence field. 
            We offer this opportunity to grow with us. Good commissions are paid. 
        </p>
        <p>
            You are expected to develop SMB accounts from finding leads to closing deals. You will be well supported by technology peers either pre- or after-sales. 
            Training will be provided for you to understand the products and technology at business level. At your side, 
            more sales experience is better and more technology background is better. We would love to listen to your story and your ambition.
        </p>
        <p>
            Please note: this position starts purely commission-based.
        </p>
        <p>
            If you are interested in it, please email your resume to team@ai-directly.com.
        </p>
    </section>
</template>

<script>
export default {
    name: 'CareerView',
};
</script>

<style scoped>
#career h3 {
    margin-top: 40px;
    color: #004b7a;
}
</style>