<template>
    <section class="trunk">
        <div id="cards-grid">
            <div class="card" :class="{ 'no-detail': !card.detail }" v-for="card in cards" :key="card.id" @click="showDetails(card.id)">
                <h2>{{ card.title }}</h2>
                <p>{{ card.content }} <a href="#" v-if="card.detail"> ...</a></p>
                <div v-if="card.id === 0">
                    Please try:
                    <ul>
                        <li>
                            <a href="https://ReadyCapital.ca?gpt=open" target="_blank" rel="noopener noreferrer" @click.stop="">Online AI Customer Service</a>
                        </li>
                        <li>
                            <a href="https://AIVIO-digital.com?gpt=open" target="_blank" rel="noopener noreferrer" @click.stop="">Digital Sales Rep</a>
                        </li>
                        <li>
                            <router-link to="/immigrun" @click.stop="">AI Immigration Consultant</router-link>
                            <!-- click propagation has to be terminated, otherwise it will cause null binding error in codes in parent objects.-->
                        </li>
                    </ul>
                </div>
                <button class="card-expand-btn" v-if="card.detail">&#8735;</button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            activeCardId: -1,
            hoverTimeout: 30000,
            cards: [
                {
                    id: 0,
                    title: 'FirmGPT',
                    content: `We help you hire ChatGPT to do your Customer Service and Sales Rep jobs. Through our FirmGPT, a ChatGPT-powered SaaS system, we tailor and integrate artificial intelligence into your website and app to respond to online service requests and sales inquiries`,
                    detail: true
                },
                {
                    id: 1,
                    title: 'TextingGPT',
                    content: `Our TextingGPT system brings ChatGPT to SMS, making today's most advanced AI accessible to a much broader population simply through cellphone text messaging. This system and service are especially valuable for those marginalized by technology, reconnecting them with cutting-edge AI, the future`,
                    detail: true
                },
                {
                    id: 2,
                    title: 'Natural Language API',
                    content: `We help enterprises build their Natural Language API (NLAPI). Powered by ChatGPT, our NLAPI application is designed and engineered to transform how enterprises interact through IT infrastructure. Here's what makes our Enterprise NLAPI a game-changer`,
                    detail: true
                },
                {
                    id: 3,
                    title: 'Multi-Factor Authentication (MFA)',
                    content: `Our MFA system and service offer a modern security solution that helps applications send out one-time passcodes (OTP) to users' cell phones through SMS. In an era where cyber threats are increasingly sophisticated, this state-of-the-art security feature is designed to protect users`,
                    detail: true
                },
                {
                    id: 4,
                    title: 'Virtual Persona',
                    content: `Before establishing the company, the founder envisioned creating virtual personas to help families create the digital presence of their departed loved ones, such as grandparents. These virtual personas allow family members to seek comfort, share feelings, and consult for advice on decision-making as if their loved ones were still with them. Please contact us for details.`,
                    detail: false
                }
            ],
        };
    },
    methods: {
        showDetails(id) {
            if (!this.cards[id].detail) return;   // like the case Virtual Persona

            this.activeCardId = id;

            const gridRect = document.getElementById('cards-grid').getBoundingClientRect();
            const cardRect = document.getElementsByClassName('card')[id].getBoundingClientRect();

            const detail = document.getElementsByClassName('card-detail')[id];
            detail.style.left = `${gridRect.left + 10}px`   // +10px to offect .cards-grid padding and its border size
            detail.style.top = `${cardRect.top + window.scrollY}px`;
            detail.style.width = `${gridRect.width * 0.916}px`;
            detail.style.display = 'block';
        }
    },
    mounted() {
        // The touchend event manage hiding the div when a tap is detected outside of its area on mobile devices.
        document.addEventListener("touchend", (event) => {
            if (this.activeCardId >= 0) {
                const detail = document.getElementsByClassName('card-detail')[this.activeCardId];
                if (!detail.contains(event.target)) {
                    detail.style.display = "none";
                    this.activeCardId = -1;
                }
            }
        });
    }
};
</script>

<style scoped>
#cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 12px;
    padding: 12px;   /* use both margin and padding to let the padding universal, namely for considering mobile, and consistant for position calculation in showDetails() */
    gap: 24px;
    justify-content: center;    /* Add this line to center the grid items */
}

.card {
    position: relative;
    padding: 32px 22px 16px 22px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #333;
    transition: color 0.3s ease;
    cursor: pointer;
    text-align: left;
}

.card:hover {
    background-color: #fbf0e6c0;
}

.card.no-detail:hover {
    background-color: inherit;
    cursor: default;
}

.card h2 {
    margin: 0 0 20px 0;
}

.card ul {
    margin-top: 3px;
    list-style-type: none;      /* Removes the bullet points */
    line-height: 1.7;
}

.card-expand-btn {
    position: absolute;
    top: 16px;
    right: 18px;
    border: none;
    background-color: transparent;
    color: #1a8cff;
    padding: 0;
    cursor: pointer;
    font-size: medium;
    font-weight: lighter;
    display: inline-block;    /* Allows the rotation to apply correctly */
    transform: rotate(225deg);    /* Rotates the element x degrees clockwise */
}

@media screen and (max-width: 1023px) {
    #cards-grid {
        margin: 0;
    }

    .card {
        max-width: 72vw;
    }

    .card-expand-btn {
        font-size: x-large;
    }
}
</style>