import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Ensure this import is correct

/* add Font Awesome to the library */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane, faMessage } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPaperPlane, faMessage, faPhone, faEnvelope);

// Dynamically injecting this <script> will eliminate the hastle of switching between production and development, by taking advantage of .env
const chatboxScript = document.createElement("script");
chatboxScript.src = `${process.env.VUE_APP_API_URL}/chat/chatbox.js?com=Directly`;
chatboxScript.defer = true;
document.head.appendChild(chatboxScript);

//
createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app');
