import MarkdownIt from 'markdown-it';
const md = new MarkdownIt();

// THIS FUNCTION PROCESSES CHAT LOG data THAT IS RECEIVED FROM SERVER, AND MAKE IT INTO A REPORT
const logShow = (coid, data) => {
    // build the report
    const report = document.getElementById("report");

    const reportTitle = document.createElement("h2");
    reportTitle.style.textAlign = "center";
    reportTitle.style.paddingTop = "30px";
    reportTitle.innerHTML = `${coid} ChatGPT Conversation Log`;
    report.appendChild(reportTitle);

    const curDate = new Date();
    const startDate = new Date();
    startDate.setDate(curDate.getDate() - (data.days - 1));   // data.days maybe different from days after processing
    const paragraph = document.createElement('p');
    paragraph.style.textAlign = 'center';
    paragraph.innerHTML = `from ${startDate.toISOString().slice(0, 10)} to ${curDate.toISOString().slice(0, 10)}`;
    report.appendChild(paragraph);

    data.report.forEach(client => {   // each ip is a client
        const clientDiv = document.createElement('div');
        clientDiv.classList.add('report-client');   // I can't use gpt-box because it is an id, not a class
        report.appendChild(clientDiv);

        if (client.ip === data.user_ip)   // ip is not reliable, since the browser could be of admin
            client.ip = `${client.ip} (${coid})`;
        const ipDiv = document.createElement('div');
        ipDiv.classList.add('report-ip');
        ipDiv.innerHTML = `ip: ${client.ip}`;
        clientDiv.appendChild(ipDiv);

        const dialogueDiv = document.createElement('div');
        dialogueDiv.classList.add('report-dialogue');
        dialogueDiv.style.padding = '10px 24px 24px 24px';
        clientDiv.appendChild(dialogueDiv);

        client.dialogue.forEach(session => {
            const { timestamp, inquiry, reply } = session;

            const timeDiv = document.createElement('div');
            timeDiv.classList.add('gpt-inquiry');
            timeDiv.innerHTML = timestamp.slice(0, -4).replace('T', ', ');
            dialogueDiv.appendChild(timeDiv);

            const inquiryDiv = document.createElement('div');
            inquiryDiv.classList.add('gpt-inquiry');   // this is purposely the right CSS
            inquiryDiv.innerHTML = `<div class="gpt-you">client:</div><div class="gpt-msg">${inquiry}</div>`;
            dialogueDiv.appendChild(inquiryDiv);

            const replyDiv = document.createElement('div');
            replyDiv.classList.add('gpt-reply');
            replyDiv.innerHTML = `<div class="gpt-you">GPT:</div><div class="gpt-msg">${md.renderInline(reply).replace(/【.*?†.*?】/g, ' ').replace('\n\n', '<p>').replace(/\n\n/g, '</p><p>').replace(/\r?\n/g, '<br>')}</div>`;
            dialogueDiv.appendChild(replyDiv);
        })
    })
}

export default logShow;
