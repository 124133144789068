<template>
    <section id="cv-builder" class="trunk">
        <div id="op-wrap">
            <router-link to="/" custom v-slot="{ navigate }">
                <button @click="navigate">
                    <span v-if="!cnDisp">Home</span>
                    <span v-if="cnDisp" class="lang-cn">首页</span>
                </button>
            </router-link>
            <select value="/cvbuild" @change="(event) => $router.push(event.target.value)" style="width: 110px;">
                <option value="/immigrun">ImmigRun</option>
                <option value="/cvbuild">CVbuilder</option>
            </select>
        </div>

        <iframeCompo coid="CVbuild" email_subject="Your resume built by AI-Directly ImmigRun GPT">
            <template #foreword>
                <h2>Resume AI</h2>
                <p>
                    This AI CV Builder crafts an American-style resume for you, customized to fit specific job descriptions.
                </p>
                <div class="gpt-greet">
                    <p>We together will build your resume in two steps:</p>
                    <ol>
                        <li>
                            Enter your profile information in any unstructured format and in any language, including skills, work history, education, certifications, testimonials, etc.
                        </li>
                        <li>
                            Paste the job description link. I’ll visit the website and analyze it to tailor your resume to match the job requirements.
                        </li>
                    </ol>
                </div>
            </template>

            <template #afterword>
                <p>
                    Once you're satisfied with the resume, you can request to have it sent to your email.
                </p>
                <p>
                    Please remain on this page while we work on it. Navigating away will drop the conversation content.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import iframeCompo from "../components/iframeCompo.vue";

export default {
    name: 'CVbuildView',
    components: {
        iframeCompo,
    },
}
</script>

<style scoped>
h2 {
    margin-top: 16px;
}


/************* */
/* Control bar */
/************* */
#op-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 12px 6% 0 0;
}

#op-wrap select,
#op-wrap button {
    width: 90px;
    height: 32px;
    margin: auto 8px;
    padding: 4px;
    background-color: #fbf0e6;
    border: 1px dashed lightgray;
    border-radius: 6px;
    box-shadow: none;
    color: #004b7a;
    font-family: Verdana, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

#op-wrap select:focus,
#op-wrap button:focus {
    outline: none;
    border-color: #004b7ae0;
}

@media screen and (max-width: 1023px) {
    #op-wrap {
        margin-right: 2%;
    }

    #op-wrap select,
    #op-wrap button {
        width: 84px;
        height: 30px;
        margin-right: 2px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: normal;
    }
}
</style>